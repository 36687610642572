import React from "react";
import PropTypes from "prop-types";

const Arrow = ({ direction }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 77 77"
    width="56"
    height="56"
    role="img"
    className={`m-page-arrow m-page-arrow--${direction}`}
    aria-hidden="true"
  >
    <title>{direction} arrow</title>
    <circle
      cx="38.5"
      cy="38.5"
      r="38.5"
      fillRule="evenodd"
      clipRule="evenodd"
      className="m-page-arrow__circle"
    />
    <path
      d="M52.2 38.5v-.1l-.1-.2s0-.1-.1-.1l-.1-.1-8.5-9c-.1-.2-.3-.2-.6-.2-.2 0-.4.1-.6.2-.3.3-.3.8 0 1.1l7.3 7.6h-24c-.4 0-.8.4-.8.8s.4.8.8.8h24l-7.3 7.6c-.3.3-.3.8 0 1.1.2.2.4.2.6.2.2 0 .4-.1.6-.2l8.7-9 .1-.1v-.1l.1-.3c-.1.1-.1.1-.1 0zm-.2-.1zm0-.1z"
      className="m-page-arrow__arrow"
    />
  </svg>
);

Arrow.propTypes = {
  direction: PropTypes.string.isRequired,
};

export default Arrow;
