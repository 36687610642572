import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import LearningResourcesSearchPage from "./LearningResourcesSearchPage";

const LearningResourcesSearchPageWrapper = ({ filters }) => {
  return (
    <Router>
      <LearningResourcesSearchPage filters={filters} />
    </Router>
  );
};

LearningResourcesSearchPageWrapper.propTypes = {
  filters: PropTypes.object,
};

export default LearningResourcesSearchPageWrapper;
