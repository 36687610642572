import React, { useRef } from "react";
import PropTypes from "prop-types";
import { IconSearch } from "../icons";

const SearchInput = ({ onSubmit, label, placeholder }) => {
  const inputEl = useRef(null);

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(inputEl.current.value);
  }

  return (
    <div className="m-search-form__input-wrapper">
      <button
        className="m-search-form__submit-button"
        type="submit"
        {...(onSubmit && { onClick: handleSubmit })}
      >
        <span className="a-hidden">Search</span>
        <IconSearch />
      </button>
      <label htmlFor="patientSearch" className="a-hidden">
        {label}
      </label>
      <input
        name="search"
        id="patientSearch"
        className="m-search-form__input"
        placeholder={placeholder}
        ref={inputEl}
        type="text"
      />
    </div>
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SearchInput;
