import "whatwg-fetch";
import qs from "query-string";

export default function apiBase({ path, params, onSuccess, onError }) {
  const url = params
    ? path +
      "?" +
      qs.stringify(params, { skipNull: true, skipEmptyString: true })
    : path;
  const controller = new AbortController();
  const { signal } = controller;
  const options = {
    headers: {
      Accept: "application/json",
    },
    credentials: "same-origin",
    signal,
  };

  const promise = window
    .fetch(url, options)
    .then((response) => {
      if (response.status !== 200) {
        // eslint-disable-next-line
        console.log(`API Request completed but status was ${response.status}`);
        onError({ response });
      } else {
        if (onSuccess) {
          response.json().then(onSuccess);
        }
      }
    })
    .catch((error) => {
      if (onError) {
        onError({ error });
      }
    });

  return { promise, controller };
}
