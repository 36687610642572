import { useState, useEffect } from "react";
import debounce from "lodash/debounce";

function useBreakpoint(size) {
  const [width, setWidth] = useState();

  useEffect(() => {
    if (!size) return;

    function handleResize() {
      setWidth(window.innerWidth);
    }

    const resizeDebounced = debounce(handleResize, 300);

    // Add event listener
    window.addEventListener("resize", resizeDebounced);

    // Call handler right away so state gets updated with initial window size
    resizeDebounced();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", resizeDebounced);
  }, []); // eslint-disable-line
  // Empty array ensures that effect is only run on mount

  return width ? width < size : false;
}

export default useBreakpoint;
