/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import PropTypes from "prop-types";

const Select = ({
  onChange,
  options,
  label,
  name,
  emptyOption,
  hideLabel,
  value,
}) => {
  const selectId = `${name}Select`;

  function handleChange(event) {
    onChange({ name, value: event.target.value });
  }

  return (
    <div className="m-select">
      <label
        className={hideLabel ? "a-hidden" : "m-select__label"}
        htmlFor={selectId}
      >
        {label}
      </label>
      <select
        className="m-select__select"
        id={selectId}
        name={name}
        {...(onChange && { onChange: handleChange })}
        {...(value !== undefined && { value })}
      >
        {emptyOption && <option value="">{emptyOption}</option>}
        {options.map(({ value, name }, i) => (
          <option key={i} value={value}>
            {name}
          </option>
        ))}
      </select>
      <svg
        className="m-select__arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        role="img"
      >
        <title>Down arrow</title>
        <path d="M6.9 10.3c-.2 0-.4-.1-.5-.2L.5 4.2c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.3 5.3 5.2-5.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1L7.5 10c-.2.3-.4.3-.6.3z" />
      </svg>
    </div>
  );
};

Select.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  emptyOption: PropTypes.string,
  hideLabel: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Select;
