import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useFilters } from "../../hooks";
import { getCurrentFilters } from "../../helpers";
import {
  Select,
  FilterPanel,
  CurrentFilters,
  SearchInput,
  FilterPanelToggle,
  SelectMonthYear,
  FormWrapper,
} from "../../shared";

const PatientSearchForm = ({ filters, onSubmit, query = {}, action }) => {
  const [state, toggleFilterPanel, updateFilters] = useFilters(query);
  const { values, filtersOpen } = state;
  const [isOpen, setIsOpen] = useState(false);
  const currentFilters = useMemo(
    () => getCurrentFilters(query, filters),
    [query, filters]
  );
  const formEl = useRef(null);

  function handleFilterSubmit(isMobile) {
    onSubmit({ ...values, page: 1 });
    if (isOpen) setIsOpen(false);
    if (!isOpen) toggleFilterPanel();
  }

  function handleSelectChange({ name, value }) {
    if (!name) return;
    updateFilters({ [name]: value });
  }

  function handleOrderChange({ name, value }) {
    if (action) {
      formEl.current.submit();
    } else {
      onSubmit({ [name]: value, page: 1 });
    }
  }

  function handleRemoveFilter(filterName) {
    const newFilter = { [filterName]: "" };
    onSubmit(newFilter);
    updateFilters(newFilter);
  }

  function handleSearch(value) {
    onSubmit({ search: value, page: 1 });
    if (isOpen) setIsOpen(false);
  }

  function handleSearchToggle(event) {
    event.preventDefault();
    setIsOpen(!isOpen);
  }

  return (
    <FormWrapper
      buttonName="Search Patients"
      isOpen={isOpen}
      onSearchToggle={handleSearchToggle}
    >
      <form {...(action && { action })} ref={formEl}>
        <div id="searchForm" className="m-search-form__search">
          <SearchInput
            label="Search patients"
            {...(!action && { onSubmit: handleSearch })}
            placeholder="Search patients by name, keyword, location, ID"
          />
          <FilterPanelToggle onClick={toggleFilterPanel} isOpen={filtersOpen} />
          {filters.order && (
            <Select
              {...(!action && {
                value: query.order,
              })}
              onChange={handleOrderChange}
              options={filters.order}
              label="Order By:"
              name="order"
              emptyOption="Choose one..."
            />
          )}
        </div>
        {!action && (
          <CurrentFilters
            isOpen={filtersOpen}
            filters={currentFilters}
            onRemoveFilter={handleRemoveFilter}
          />
        )}
        <FilterPanel
          isOpen={filtersOpen}
          {...(onSubmit && { onSubmit: handleFilterSubmit })}
        >
          {filters.species && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.species,
              })}
              options={filters.species}
              label="Species:"
              name="species"
              emptyOption="Species"
              hideLabel
            />
          )}
          {filters.rescue_location && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.rescue_location,
              })}
              options={filters.rescue_location}
              label="Rescue Location:"
              name="rescue_location"
              emptyOption="Rescue Location"
              hideLabel
            />
          )}
          {filters.diagnosis && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.diagnosis,
              })}
              options={filters.diagnosis}
              label="Diagnosis:"
              name="diagnosis"
              emptyOption="Diagnosis"
              hideLabel
            />
          )}
          {filters.status && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.status,
              })}
              options={filters.status}
              label="Status:"
              name="status"
              emptyOption="Status"
              hideLabel
            />
          )}
          <SelectMonthYear
            label="Rescue Date"
            name="rescue_date"
            min="2013-01"
            {...(!action && {
              onChange: handleSelectChange,
              value: values.rescue_date,
            })}
          />
        </FilterPanel>
      </form>
    </FormWrapper>
  );
};

PatientSearchForm.propTypes = {
  filters: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  query: PropTypes.object,
  action: PropTypes.string,
};

export default PatientSearchForm;
