import React from "react";
import PropTypes from "prop-types";
import NewsRecord from "./NewsRecord";
import { ContentBlock } from "../../shared";

const NewsResults = ({ records, total }) => {
  return (
    records.length > 0 && (
      <ContentBlock className="m-record-results">
        <header className="a-hidden">
          <h2>{total} Results</h2>
        </header>
        <div className="m-grid m-grid--2">
          <div className="m-grid__items">
            {records.map((news) => (
              <NewsRecord key={news.id} news={news} />
            ))}
          </div>
        </div>
      </ContentBlock>
    )
  );
};

NewsResults.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
};

export default NewsResults;
