import PropTypes from "prop-types";

const patientShape = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  image: PropTypes.shape({
    path: PropTypes.string,
    alt: PropTypes.string,
  }),
  sex: PropTypes.string,
  status: PropTypes.string,
  species_id: PropTypes.number,
  image_default: PropTypes.string,
  image_retina: PropTypes.string,
  url: PropTypes.string,
  species_name: PropTypes.string,
  species_threatened_status: PropTypes.string,
};

export { patientShape };
