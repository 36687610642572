import React from "react";
import PropTypes from "prop-types";
import LearningResourceRecord from "./LearningResourceRecord";
import { ContentBlock } from "../../shared";

const LearningResourcesResults = ({ records, total }) => {
  return (
    records.length > 0 && (
      <ContentBlock className="m-record-results">
        <header className="a-hidden">
          <h2>{total} Results</h2>
        </header>
        <div className="m-grid m-grid--2">
          <div className="m-grid__items">
            {records.map((publication) => (
              <LearningResourceRecord
                key={publication.id}
                publication={publication}
              />
            ))}
          </div>
        </div>
      </ContentBlock>
    )
  );
};

LearningResourcesResults.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
};

export default LearningResourcesResults;
