import React from "react";

const IconSearch = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className="a-color-secondary"
    >
      <title>magnifying glass</title>
      <path d="M9.666 0C4.34 0 0 4.34 0 9.666c0 5.327 4.34 9.666 9.666 9.666 2.303 0 4.42-.813 6.083-2.166l6.541 6.541c.391.39 1.026.39 1.417 0a.99.99 0 000-1.406l-6.541-6.541a9.62 9.62 0 002.166-6.094C19.332 4.34 14.992 0 9.666 0zM9.6 1.8c4.32 0 7.8 3.48 7.8 7.8s-3.48 7.8-7.8 7.8-7.8-3.48-7.8-7.8 3.48-7.8 7.8-7.8z" />
    </svg>
  );
};

IconSearch.propTypes = {};

export default IconSearch;
