import { useReducer } from "react";

function reducer(state, action) {
  const { type, payload } = action;
  const { values, filtersOpen } = state;

  switch (type) {
    case "UPDATE_FILTER_VALUE":
      return { ...state, values: { ...values, ...payload } };
    case "FILTERS_OPEN_TOGGLE":
      return { ...state, filtersOpen: !filtersOpen };
    default:
      throw new Error();
  }
}

export default function useFilters(query) {
  const [state, dispatch] = useReducer(reducer, {
    values: { ...query },
    filtersOpen: false,
  });

  function toggleFilterPanel() {
    if (state.filtersOpen) {
      document.body.classList.remove("has-filters-open");
    } else {
      document.body.classList.add("has-filters-open");
    }

    dispatch({ type: "FILTERS_OPEN_TOGGLE" });
  }

  function updateFilters(filter) {
    dispatch({
      type: "UPDATE_FILTER_VALUE",
      payload: filter,
    });
  }

  return [state, toggleFilterPanel, updateFilters];
}
