import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

const monthOptions = [
  { value: "01", name: "January" },
  { value: "02", name: "February" },
  { value: "03", name: "March" },
  { value: "04", name: "April" },
  { value: "05", name: "May" },
  { value: "06", name: "June" },
  { value: "07", name: "July" },
  { value: "08", name: "August" },
  { value: "09", name: "September" },
  { value: "10", name: "October" },
  { value: "11", name: "November" },
  { value: "12", name: "December" },
];

const yearOptions = [];
const now = new Date();
for (let i = now.getFullYear(); i >= 2014; i--) {
  yearOptions.push({ value: i, name: i });
}

const SelectMonthYear = ({ name, label, onChange, value }) => {
  const yearEl = useRef(null);
  const monthEl = useRef(null);
  const [hiddenValue, setHiddenValue] = useState(value || "");

  function handleChange() {
    const monthValue = monthEl.current.value;
    const yearValue = yearEl.current.value;
    let value;

    if (yearValue) {
      value = monthValue ? `${yearValue}-${monthValue}` : yearValue;
    } else {
      value = monthValue ? `${now.getFullYear()}-${monthValue}` : null;
    }

    setHiddenValue(value || "");
    if (onChange) onChange({ name, value });
  }

  return (
    <div className="m-select-group">
      <div className="a-hidden">{label}</div>
      <div className="m-select">
        <label htmlFor={`${name}SelectMonth`} className="a-hidden">
          Month
        </label>
        <select
          className="m-select__select"
          id={`${name}SelectMonth`}
          ref={monthEl}
          onBlur={handleChange}
          {...(value &&
            value.length === 7 && { defaultValue: value.slice(5, 7) })}
        >
          <option value="">Month</option>
          {monthOptions.map(({ value, name }, i) => (
            <option key={i} value={value}>
              {name}
            </option>
          ))}
        </select>
        <svg
          className="m-select__arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          role="img"
        >
          <title>Down arrow</title>
          <path d="M6.9 10.3c-.2 0-.4-.1-.5-.2L.5 4.2c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.3 5.3 5.2-5.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1L7.5 10c-.2.3-.4.3-.6.3z" />
        </svg>
      </div>
      <div className="m-select">
        <label htmlFor={`${name}SelectYear`} className="a-hidden">
          Year
        </label>
        <select
          className="m-select__select"
          id={`${name}SelectYear`}
          ref={yearEl}
          onBlur={handleChange}
          {...(value && { defaultValue: value.slice(0, 4) })}
        >
          <option value="">Year</option>
          {yearOptions.map(({ value, name }, i) => (
            <option key={i} value={value}>
              {name}
            </option>
          ))}
        </select>
        <svg
          className="m-select__arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          role="img"
        >
          <title>Down arrow</title>
          <path d="M6.9 10.3c-.2 0-.4-.1-.5-.2L.5 4.2c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l5.3 5.3 5.2-5.2c.3-.3.8-.3 1.1 0s.3.8 0 1.1L7.5 10c-.2.3-.4.3-.6.3z" />
        </svg>
      </div>
      <input type="hidden" name={name} value={hiddenValue} />
    </div>
  );
};

SelectMonthYear.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default SelectMonthYear;
