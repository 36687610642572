import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ContentBlock from "./ContentBlock";

const LoadingIndicator = ({ className }) => {
  return (
    <ContentBlock className="t-align-center">
      <p className="a-hidden">Loading...</p>
      <svg
        className={classNames("m-loading-indicator", className)}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 259 259"
        width="129"
        height="129"
        aria-hidden="true"
      >
        <title>TMMC logo loading indicator</title>
        <path
          d="M129.5 258.5c-71.1 0-129-57.9-129-129S58.4.5 129.5.5s129 57.9 129 129-57.9 129-129 129zm0-253c-68.4 0-124 55.6-124 124s55.6 124 124 124 124-55.6 124-124-55.6-124-124-124z"
          className="m-loading-indicator__circle"
        />
        <path
          d="M129.5 258.5c-71.1 0-129-57.9-129-129S58.4.5 129.5.5s129 57.9 129 129h-5c0-68.4-55.6-124-124-124s-124 55.6-124 124 55.6 124 124 124v5z"
          className="m-loading-indicator__progress"
        />
        <path
          d="M126.8 98.6c-.4.1-.9.2-1.3.7-.4.5-.4 1-.4 1.5 0 .2.1.6.3.7.1.1.6-.1.7-.2.3-.2.7-.4.9-.8.3-.5.4-.7.4-1.2 0-.3 0-.7-.1-.8.1 0-.2 0-.5.1m30.4 66.4c-5.9 13.8-19.6 21.7-37.1 16.3-15.9-4.9-21.4-19.3-19.9-37.3.3-3.6 1.7-8.8 2.9-12.1 3.4-9.7 8.6-18.5 10.3-25.9 1.4-6.5 5.4-12.6 13.3-11.3 1.6.3 2.8-1.3 4.6-1.5 2.2-.3 3 3.5 2 7.7-1.4 6-5.6 9.7-6.9 17.8-.8 5.4-1 10 2.9 13.6 1.2 1.2 3.6 3.3 4.9 4.4 3.4 3 6.8 6.1 9.6 9.9 2.3 3.1 3.4 4.6 4.8 8.6 1.3 3.6-1.2 4.7-3 5.2s-4.8.2-6.4-.1c-1.6-.3-4.6-1-7.7-2.6-3.1-1.7-5.7-4.1-6-4.5-1-1.1-1.7-.9-2.2-.6-.5.4-.6 1 .3 2 1.6 1.7 4.6 4.1 7.2 5.3 3.8 1.8 6.7 2.9 12.2 2.9 4.4.2 9.6-2.6 8-7.7-1-3.3-3.2-7.4-6.8-11.7-3-3.6-6.8-7.2-9.1-9.2-1-.9-5.8-4.7-5.8-6.7 4.7-7.3 6.5-12.7 7.1-19.6.3-3.1 1.6-13-3.8-16.7-2.6-1.8-8 1.5-11 1.7-2.4.2-2.7-2.1-1.8-5 .9-2.9 4.8-12.5 6.6-16.6.8-1.9 2-3.4 3.3-3.4 1.4 0 2.5 1.3 3.4 3.3 1.7 3.8 6.5 19.1 11.8 32.2 3.9 9.7 7 18.9 10 26.1 4.8 11.8 7.4 23.6 2.3 35.5"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    </ContentBlock>
  );
};

LoadingIndicator.propTypes = {
  className: PropTypes.string,
};

export default LoadingIndicator;
