import { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import qs from "query-string";

const formatQuery = (query) => {
  return (
    "?" +
    qs.stringify(query, {
      skipNull: true,
      skipEmptyString: true,
      arrayFormat: "separator",
      arrayFormatSeparator: "-",
    })
  );
};

const getQuery = (queryString) => {
  return qs.parse(queryString);
};

function useQuery(initialValue) {
  const history = useHistory();
  const location = useLocation();

  const [value, setValue] = useState(
    getQuery(history.location.search) || initialValue
  );

  const onSetValue = useCallback(
    (newValue) => {
      const qsValue = formatQuery(newValue);
      history.push(history.location.pathname + qsValue);
      setValue(newValue);
    },
    [history]
  );

  // Keep query value up to date if the user navigates back/forward
  // in history, or clicks on a page link
  useEffect(() => {
    const queryString = formatQuery(value);
    if (location.search && location.search !== queryString) {
      const newQuery = getQuery(location.search);
      const newString = formatQuery(newQuery);
      setValue(newQuery);
      history.replace(newString);
    }
  }, [location, value, setValue]); // eslint-disable-line

  return [value, onSetValue];
}

export default useQuery;
export { formatQuery, getQuery };
