import React from "react";
import PropTypes from "prop-types";

const IconArrow = ({ rotate = 0 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      role="img"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <title>arrow</title>
      <path d="M12.8 8L5.2.7c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 .9l7.1 6.9-7.1 6.9c-.3.3-.3.7 0 .9.3.3.7.3 1 0L12.8 9c.1-.1.2-.3.2-.5s-.1-.3-.2-.5z" />
    </svg>
  );
};

IconArrow.propTypes = {
  rotate: PropTypes.number,
};

export default IconArrow;
