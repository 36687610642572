import formApiRequest from "../../api/Form";
import Sb from "../abstract/StatefulBehavior";

const DEFAULT_STATE = {
  attachments: 0,
  formState: null,
};

export default class ReportAnAnimal extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;
    this.state = DEFAULT_STATE;

    this.addListeners();
    this.addRecaptchaScript();
  }

  addListeners() {
    const { form, uploadButton, resetForm } = this.refs;

    form.addEventListener("submit", this.handleSubmit);
    uploadButton.addEventListener("change", this.handleFileSelect);
    resetForm.addEventListener("click", this.handleReset);
  }

  addRecaptchaScript() {
    const scriptEl = document.createElement("script");
    scriptEl.type = "text/javascript";
    scriptEl.async = true;
    scriptEl.defer = true;
    scriptEl.src = "https://www.google.com/recaptcha/api.js?render=onload";
    document.body.appendChild(scriptEl);
  }

  update = () => {
    const { attachments, formState } = this.state;
    const {
      uploadLabel,
      form,
      loadingIndicator,
      successMessage,
      errorMessage,
    } = this.refs;

    const label = attachments
      ? `${attachments} Photo${attachments > 1 ? "s" : ""} Selected`
      : "Upload Photos";
    uploadLabel.innerHTML = label;

    switch (formState) {
      case "loading":
        loadingIndicator.removeAttribute("hidden");
        errorMessage.setAttribute("hidden", true);
        form.setAttribute("hidden", true);
        this.scrollToTop();
        break;
      case "success":
        successMessage.removeAttribute("hidden");
        loadingIndicator.setAttribute("hidden", true);
        break;
      case "error":
        successMessage.setAttribute("hidden", true);
        loadingIndicator.setAttribute("hidden", true);
        errorMessage.removeAttribute("hidden");
        form.removeAttribute("hidden");
        this.setErrorMessage();
        break;
      default:
        successMessage.setAttribute("hidden", true);
        loadingIndicator.setAttribute("hidden", true);
        errorMessage.setAttribute("hidden", true);
        form.removeAttribute("hidden");
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    formApiRequest(formData, this.handleSuccess, this.handleError);
    this.setState({ formState: "loading" });
  };

  handleSuccess = ({ success, errors, ...otherProps }) => {
    this.setState({ formState: success ? "success" : "error", errors });
  };

  handleError = (e) => {
    this.setState({ formState: "error" });
  };

  handleFileSelect = (e) => {
    if (!e.target.files) {
      this.setState({ attachments: 0 });
    } else {
      const files = e.target.files;
      this.setState({ attachments: files.length });
    }
  };

  // The entire form needs to reload for the recaptcha
  handleReset = () => {
    location.reload();
  };

  setErrorMessage() {
    const { errors } = this.state;
    const { errorMessage } = this.refs;
    let errorText = "<p>There was an error processing your report.</p>";

    if (errors && Object.keys(errors).length > 0) {
      errorText += "<ul>";
      Object.keys(errors).forEach((error) => {
        errorText += "<li>" + errors[error].join(", ") + "</li>";
      });
      errorText += "</ul>";
    } else {
      errorText +=
        "<p>Please share your observations by sending an email to <a href='mailto:CetaceanFieldResearch@tmmc.org'>CetaceanFieldResearch@tmmc.org</a>.</p>";
    }

    errorMessage.innerHTML = errorText;
  }

  scrollToTop() {
    window.scrollTo(0, this.el.offsetTop);
  }
}
