import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

class NewsRecord extends PureComponent {
  render() {
    const WIDTH = 216;
    const HEIGHT = 168;

    const {
      news: {
        id,
        name,
        image_retina: imageRetina,
        image_default: imageDefault,
        url,
        date,
      },
    } = this.props;

    return (
      <div className="m-record-results__item">
        {(imageRetina || imageDefault) && (
          <figure className="m-record-results__figure">
            <a
              href={url}
              className="m-image-link m-record-results__image-link"
              aria-hidden="true"
            >
              <div className="m-image">
                <img
                  className="m-image__img m-record-results__img"
                  src={imageDefault}
                  srcSet={`${imageRetina} ${
                    WIDTH * 2
                  }w, ${imageDefault} ${WIDTH}w`}
                  sizes={`(max-width: 620px) 148px, 100vw`}
                  width={WIDTH}
                  height={HEIGHT}
                  alt={""}
                  loading="lazy"
                />
              </div>
            </a>
          </figure>
        )}
        <div className="m-record-results__body">
          {name && (
            <div className="m-record-results__text">
              <p>{name}</p>
              {date && (
                <p className="m-record-results__date">
                  {format(new Date(date), "MMMM d, Y")}
                </p>
              )}
            </div>
          )}
          {url && <a href={url}>Read More</a>}
        </div>
      </div>
    );
  }
}

NewsRecord.propTypes = {
  news: PropTypes.object,
};

export default NewsRecord;
