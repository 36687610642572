export default class ClickToHide {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;

    this.bindEvents();
  }

  bindEvents() {
    this.el.addEventListener("click", this.hideElement);
  }

  hideElement = (event) => {
    this.el.setAttribute("aria-hidden", true);
    this.el.classList.add("a-fade-out");
  };
}
