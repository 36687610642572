import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { LoadingIndicator, NoResults, Pagination } from "../../shared";
import PatientSearchForm from "../PatientSearchForm";
import PatientResults from "./PatientResults";
import { useApi, useQuery } from "../../hooks";
import { scrollToRef } from "../../helpers";

const PatientSearchPage = ({ filters }) => {
  const scrollRef = useRef(null);
  const [query, setQuery] = useQuery();
  const [state, fetch, fetchNextPage] = useApi("/api/v1/patients");

  const { isLoading, isError, records, meta, pagination } = state;

  useEffect(() => {
    fetch(query);
  }, [query]); // eslint-disable-line

  useEffect(() => {
    if (isLoading) scrollToRef(scrollRef);
  }, [isLoading]);

  function handleSubmit(values) {
    setQuery({ ...query, ...values });
  }

  return (
    <div ref={scrollRef}>
      <PatientSearchForm
        onSubmit={handleSubmit}
        filters={filters}
        query={query}
      />
      <div aria-live="assertive">
        {records.length > 0 && (
          <>
            <PatientResults patients={records} total={meta.total} />
            {pagination.totalPages > 1 && (
              <Pagination
                totalPages={pagination.totalPages}
                currentPage={pagination.currentPage}
              />
            )}
          </>
        )}
        {isLoading && <LoadingIndicator />}
        {!isLoading && !isError && meta && meta.total === 0 && <NoResults />}
        {!isLoading && isError && <NoResults isError />}
      </div>
    </div>
  );
};

PatientSearchPage.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default PatientSearchPage;
