import React from "react";
import PropTypes from "prop-types";
import ContentBlock from "./ContentBlock";

const TRY_AGAIN = "Please enter a new search, or remove filters.";

// eslint-disable-next-line max-len
const DEFAULT_MESSAGE = `There are no results that match your search. ${TRY_AGAIN}`;

const ERROR_MESSAGE = `There was an error returning results. ${TRY_AGAIN}`;

const NoResults = ({ isError }) => {
  return (
    <ContentBlock className="t-align-center">
      <p className="t-heading-quaternary">
        {isError ? ERROR_MESSAGE : DEFAULT_MESSAGE}
      </p>
    </ContentBlock>
  );
};

NoResults.propTypes = {
  isError: PropTypes.bool,
};

export default NoResults;
