import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useFilters } from "../../hooks";
import { getCurrentFilters } from "../../helpers";
import {
  Select,
  FilterPanel,
  CurrentFilters,
  SearchInput,
  FilterPanelToggle,
  FormWrapper,
} from "../../shared";

const NewsSearchForm = ({ filters, onSubmit, query = {}, action }) => {
  const [state, toggleFilterPanel, updateFilters] = useFilters(query);
  const { values, filtersOpen } = state;
  const [isOpen, setIsOpen] = useState(false);
  const currentFilters = useMemo(
    () => getCurrentFilters(query, filters),
    [query, filters]
  );
  const formEl = useRef(null);

  function handleFilterSubmit() {
    onSubmit({ ...values, page: 1 });
    if (isOpen) setIsOpen(false);
    if (!isOpen) toggleFilterPanel();
  }

  function handleSelectChange({ name, value }) {
    if (!name) return;
    updateFilters({ [name]: value });
  }

  function handleOrderChange({ name, value }) {
    if (action) {
      formEl.current.submit();
    } else {
      onSubmit({ [name]: value, page: 1 });
    }
  }

  function handleRemoveFilter(filterName) {
    const newFilter = { [filterName]: "" };
    onSubmit(newFilter);
    updateFilters(newFilter);
  }

  function handleSearch(value) {
    onSubmit({ search: value, page: 1 });
    if (isOpen) setIsOpen(false);
  }

  function handleSearchToggle(event) {
    event.preventDefault();
    setIsOpen(!isOpen);
  }

  return (
    <FormWrapper
      buttonName="Search News"
      isOpen={isOpen}
      onSearchToggle={handleSearchToggle}
    >
      <form {...(action && { action })} ref={formEl}>
        <div id="searchForm" className="m-search-form__search">
          <SearchInput
            label="Search news"
            {...(!action && { onSubmit: handleSearch })}
            placeholder="Search by person, keyword, species"
          />
          <FilterPanelToggle onClick={toggleFilterPanel} isOpen={filtersOpen} />
          {filters.order && (
            <Select
              {...(!action && {
                value: query.order,
              })}
              onChange={handleOrderChange}
              options={filters.order}
              label="Order By:"
              name="order"
            />
          )}
        </div>
        <CurrentFilters
          isOpen={filtersOpen}
          filters={currentFilters}
          onRemoveFilter={handleRemoveFilter}
        />
        <FilterPanel
          isOpen={filtersOpen}
          {...(onSubmit && { onSubmit: handleFilterSubmit })}
        >
          {filters.species && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.species,
              })}
              options={filters.species}
              label="Species:"
              name="species"
              emptyOption="Species"
              hideLabel
            />
          )}
          {filters.people && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.people,
              })}
              options={filters.people}
              label="People:"
              name="people"
              emptyOption="People"
              hideLabel
            />
          )}
          {filters.topic && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.topic,
              })}
              options={filters.topic}
              label="Topic:"
              name="topic"
              emptyOption="Topic"
              hideLabel
            />
          )}
          {filters.type && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.type,
              })}
              options={filters.type}
              label="Type:"
              name="type"
              emptyOption="Type"
              hideLabel
            />
          )}
        </FilterPanel>
      </form>
    </FormWrapper>
  );
};

NewsSearchForm.propTypes = {
  filters: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  query: PropTypes.object,
  action: PropTypes.string,
};

export default NewsSearchForm;
