export default function formApiRequest(data, onSuccess, onError) {
  const url = "/api/v1/form";
  const promise = window
    .fetch(url, {
      method: "POST",
      body: data,
    })
    .then((response) => {
      if (response.status !== 200) {
        // eslint-disable-next-line
        console.log(`API Request completed but status was ${response.status}`);
        onError({ response });
      } else {
        if (onSuccess) {
          response.json().then(onSuccess);
        }
      }
    })
    .catch(onError);
  return promise;
}
