import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import NewsSearchPage from "./NewsSearchPage";

const NewsSearchPageWrapper = ({ filters }) => {
  return (
    <Router>
      <NewsSearchPage filters={filters} />
    </Router>
  );
};

NewsSearchPageWrapper.propTypes = {
  filters: PropTypes.object,
};

export default NewsSearchPageWrapper;
