import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PropTypes from "prop-types";
import PatientSearchPage from "./PatientSearchPage";

const PatientSearchPageWrapper = ({ filters }) => {
  return (
    <Router>
      <PatientSearchPage filters={filters} />
    </Router>
  );
};

PatientSearchPageWrapper.propTypes = {
  filters: PropTypes.object.isRequired,
};

export default PatientSearchPageWrapper;
