import format from "date-fns/format";
import parse from "date-fns/parse";
const dateRegex = /\d\d\d\d-\d\d/;

export default function getCurrentFilters(query, filters) {
  if (!query || !filters) return;

  return Object.keys(query)
    .filter((key) => {
      return (
        query[key] && key !== "order" && (key === "search" || filters[key])
      );
    })
    .map((key) => {
      const value = query[key];
      let name = "";

      if (dateRegex.test(value)) {
        const date = parse(value, "yyyy-MM", new Date());
        name = format(date, "MMMM yyyy");
      } else {
        const filter = filters[key]
          ? filters[key].find((item) => String(item.value) === String(value))
          : null;
        name = filter ? filter.name : value;
      }

      return { key, name };
    });
}
