import React from "react";
import PropTypes from "prop-types";
import { IconFilter } from "../icons";

const FilterPanelToggle = ({ onClick, isOpen }) => {
  return (
    <button
      className="m-search-form__filter-button"
      onClick={onClick}
      role="tab"
      aria-expanded={isOpen}
      aria-controls="filtersPanel"
      aria-label={isOpen ? "Close Filters" : "Open Filters"}
      id="filterToggle"
      type="button"
    >
      <IconFilter className="m-search-form__filter-icon" />
      <span>Filter</span>
    </button>
  );
};

FilterPanelToggle.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default FilterPanelToggle;
