/*
Adds FB SDK script if a Facebook Video is present on the page
*/

export default class FacebookVideoSdk {
  constructor() {
    this.checkScript();
  }

  get elId() {
    return "fbSDK";
  }

  get url() {
    return "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2";
  }

  checkScript = () => {
    const script = document.getElementById(this.elId);
    if (script && script.src === this.url) return true;
    this.addScript();
  };

  addScript = () => {
    const jsCode = document.createElement("script");
    jsCode.setAttribute("id", this.elId);
    jsCode.setAttribute("src", this.url);
    jsCode.setAttribute("async", "");
    jsCode.setAttribute("defer", "");
    document.body.appendChild(jsCode);
  };
}
