export default {
  cellAlign: "left",
  selectedAttraction: 0.075,
  friction: 0.48,
  pageDots: true,
  prevNextButtons: false,
  lazyLoad: true,
  adaptiveHeight: false,
  contain: true,
  imagesLoaded: true,
  wrapAround: false,
};
