import React from "react";
import PropTypes from "prop-types";

const IconFilter = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 25"
      width="31"
      height="25"
      role="img"
      className={className}
    >
      <title>filter icon</title>
      <path
        className="st0"
        d="M.5 2.9h5c0 .1.1.3.1.4.4 1 1.3 1.6 2.3 1.6.6 0 1.3-.3 1.7-.7.3-.3.6-.8.7-1.2h19.5c.3 0 .5-.2.5-.5S30 2 29.7 2H10.2C10 1.2 9.5.5 8.8.2c-.9-.4-2-.2-2.7.5-.3.4-.5.8-.6 1.3h-5c-.3 0-.5.2-.5.4s.2.5.5.5zm6-1c.2-.6.8-.9 1.4-.9.8 0 1.5.7 1.5 1.5-.1.5-.4 1.1-1 1.3-.5.2-1.2.1-1.6-.3-.4-.4-.5-1.1-.3-1.6zM29.7 11.7h-4.9c-.2-.8-.7-1.5-1.4-1.8-.9-.4-2-.2-2.7.5-.3.3-.6.8-.7 1.2H.5c-.3 0-.5.2-.5.5s.2.5.5.5h19.6c0 .1.1.3.1.4.4.9 1.3 1.5 2.3 1.5.6 0 1.3-.3 1.7-.7.3-.3.6-.8.7-1.2h4.9c.3 0 .5-.2.5-.5s-.3-.4-.6-.4zM23 13.5c-.5.2-1.2.1-1.6-.3-.4-.4-.5-1-.3-1.6.2-.5.8-.9 1.4-.9.8 0 1.5.7 1.5 1.5-.1.6-.4 1.1-1 1.3zM29.7 21.5H15.1c-.2-.8-.7-1.5-1.4-1.8-.9-.4-2-.2-2.7.5-.3.3-.6.8-.7 1.2H.5c-.3 0-.5.2-.5.5s.2.5.5.5h9.9c0 .1.1.3.1.4.4.9 1.3 1.5 2.3 1.5.6 0 1.3-.3 1.7-.7.3-.3.6-.8.7-1.2h14.6c.3 0 .5-.2.5-.5s-.3-.4-.6-.4zm-16.4 1.8c-.5.2-1.2.1-1.6-.3-.4-.4-.5-1-.3-1.6.2-.5.8-.9 1.4-.9.8 0 1.5.7 1.5 1.5-.1.5-.5 1.1-1 1.3z"
      />
    </svg>
  );
};

IconFilter.propTypes = {
  className: PropTypes.string,
};

export default IconFilter;
