import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ContentBlock = ({ background, children, className }) => {
  return (
    <section
      className={classNames(
        `m-content-block m-content-block--block a-bg-${background || "white"}`,
        className
      )}
    >
      <div className="m-content-block__inner">{children}</div>
    </section>
  );
};

ContentBlock.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContentBlock;
