import Sb from "../abstract/StatefulBehavior";
import throttle from "lodash/throttle";
import { ResizeObserver as RoPolyfill } from "@juggle/resize-observer";

const ResizeObserver = (window && window.ResizeObserver) || RoPolyfill;

export default class ToggleTab extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      active: 0,
      open: this.isMobile,
      isMobile: this.isMobile,
    };

    this.el = el;
    this.refs = refs;
    this.props = props;

    this.bindEvents();
    this.update();
  }

  get activeTabArea() {
    return this.refs.tabArea[this.state.active];
  }

  get tabButtons() {
    const { tabButton } = this.refs;
    if (!tabButton) return [];
    return tabButton.length ? tabButton : [tabButton];
  }

  get tabAreas() {
    const { tabArea } = this.refs;
    if (!tabArea) return [];
    return tabArea.length ? tabArea : [tabArea];
  }

  get isMobile() {
    return window.innerWidth < 850;
  }

  update = () => {
    this.setActiveTab();
    this.setActiveArea();
    this.toggleTabs();
    this.setContentHeight();
  };

  bindEvents() {
    const { tabsToggle } = this.refs;
    if (this.tabButtons === 0) return;

    this.tabButtons.forEach((tab, index) => {
      tab.addEventListener("click", this.handleTabSwitch);
    });

    if (tabsToggle) {
      tabsToggle.addEventListener("click", this.handleTabsToggle);
    }

    const trottledResize = throttle(this.handleResize, 200);
    this.resizeObserver = new ResizeObserver(trottledResize);
    this.resizeObserver.observe(this.el);
  }

  handleResize = (entries) => {
    entries.forEach((entry) => {
      if (entry.target === this.el) {
        this.setContentHeight();

        // Check if mobile view
        const isMobileWidth = this.isMobile;
        const { isMobile, open } = this.state;

        if (isMobileWidth !== isMobile) {
          this.setState({ isMobile: isMobileWidth, open: !isMobileWidth });
        }
      }
    });
  };

  handleTabSwitch = (event) => {
    event.preventDefault();
    const button = event.target;
    const { index } = button.dataset;

    this.setState({
      active: Number(index, 10),
      open: false,
    });
  };

  setActiveTab = () => {
    const { active } = this.state;

    this.tabButtons.forEach((button, i) => {
      button.setAttribute("aria-selected", i === active);
    });
  };

  setActiveArea = () => {
    const { active } = this.state;

    this.tabAreas.forEach((area, i) => {
      area.setAttribute("aria-hidden", i !== active);
    });
  };

  setContentHeight = () => {
    const tabArea = this.activeTabArea;
    if (!tabArea) return;
    const height = Math.round(tabArea.getBoundingClientRect().height);
    this.el.style.setProperty("--tab-area-height", `${height}px`);
  };

  toggleTabs = () => {
    const { tabs, tabsToggle } = this.refs;
    if (!tabsToggle) return;
    const { open } = this.state;
    tabs.setAttribute("aria-expanded", !open);
    tabsToggle.setAttribute("aria-selected", !open);
  };

  handleTabsToggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };
}
