import { useReducer } from "react";
import apiBase from "../../api/apiBase";
import get from "lodash/get";

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_INIT":
      return { ...state, isLoading: true, isError: false, records: [] };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        ...payload,
      };
    case "FETCH_NEXT_PAGE":
      return { ...state, isLoading: true, isError: false };
    case "FETCH_NEXT_SUCCESS":
      return {
        ...state,
        records: [...state.records, ...payload.records],
        isLoading: false,
        isError: false,
        pagination: payload.pagination,
        meta: payload.meta,
      };
    case "FETCH_FAILURE":
      return { ...state, isLoading: false, isError: true };
    default:
      throw new Error();
  }
}

export default function useApi(path) {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
    records: [],
  });

  function fetch(query) {
    if (state.isLoading) return;

    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_SUCCESS", payload: res });
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_INIT" });

    apiBase({
      path: path,
      params: query,
      onSuccess: handleSuccess,
      onError: handleError,
    });
  }

  function fetchNextPage(query) {
    const { isLoading, isError, pagination } = state;

    const currentPage = get(pagination, "currentPage", 0);
    const totalPages = get(pagination, "totalPages", 0);

    if (isLoading || currentPage >= totalPages) return;

    const handleSuccess = (res) => {
      dispatch({ type: "FETCH_NEXT_SUCCESS", payload: res });
    };

    const handleError = (error) => {
      dispatch({ type: "FETCH_FAILURE", payload: error });
    };

    dispatch({ type: "FETCH_NEXT_PAGE" });

    apiBase({
      path: path,
      params: { ...query, page: currentPage + 1 },
      onSuccess: handleSuccess,
      onError: handleError,
    });
  }

  return [state, fetch, fetchNextPage];
}
