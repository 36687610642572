// Checks if the accordion ID or any accordion child element IDs
// match the url hash
// If there's a match, open the accordion

export default class Accordion {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;
    this.props = props;

    this.bindEvents();
    this.checkAnchorLink(window.location.hash);
  }

  bindEvents() {
    window.addEventListener("hashchange", () => {
      this.checkAnchorLink(window.location.hash);
    });
  }

  checkAnchorLink = (hash) => {
    if (!hash) return;

    const innerAnchor = this.el.querySelectorAll(hash);

    if (
      `#${this.props.anchor}` === hash ||
      (innerAnchor && innerAnchor.length > 0)
    ) {
      this.el.open = true;
    }
  };
}
