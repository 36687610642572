import React from "react";
import PropTypes from "prop-types";
import { patientShape } from "../../shapes";
import PatientRecord from "./PatientRecord";
import { ContentBlock } from "../../shared";

const PatientResults = ({ patients, total }) => {
  return (
    <ContentBlock className="m-record-results">
      {patients.length > 0 ? (
        <>
          <header className="a-hidden">
            <h2>{total} Results</h2>
            <p className="t-copy-caption">
              This list was last updated format(lastUpdated, Pp)
            </p>
          </header>
          <div className="m-grid m-grid--2">
            <div className="m-grid__items">
              {patients.map((item) => (
                <PatientRecord key={item.id} patient={item} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="m-rte">
          <p>No results found.</p>
        </div>
      )}
    </ContentBlock>
  );
};

PatientResults.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.shape(patientShape)),
  total: PropTypes.number,
};

export default PatientResults;
