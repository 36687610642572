import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import {
  CurrentFilters,
  FilterPanel,
  FilterPanelToggle,
  SearchInput,
  Select,
  FormWrapper,
} from "../../shared";
import { getCurrentFilters } from "../../helpers";
import { useFilters } from "../../hooks";

const PublicationSearchForm = ({ action, filters, query, onSubmit }) => {
  const [state, toggleFilterPanel, updateFilters] = useFilters(query);
  const { values, filtersOpen } = state;
  const [isOpen, setIsOpen] = useState(false);
  const currentFilters = useMemo(
    () => getCurrentFilters(query, filters),
    [query, filters]
  );
  const formEl = useRef(null);

  // Immediately submit order changes to parent
  function handleOrderChange({ name, value }) {
    if (!name) return;
    if (action) {
      formEl.current.submit();
    } else {
      onSubmit({ [name]: value, page: 1 });
    }
  }

  // Keep track of filter values
  function handleSelectChange({ name, value }) {
    updateFilters({
      [name]: value,
    });
  }

  // Submit filters to parent when Apply is clicked
  function handleFilterSubmit() {
    onSubmit({ ...values, page: 1 });
    if (isOpen) setIsOpen(false);
    if (!isOpen) toggleFilterPanel();
  }

  function handleSearch(value) {
    onSubmit({ search: value, page: 1 });
    if (isOpen) setIsOpen(false);
  }

  function handleRemoveFilter(filterName) {
    if (!filterName) return;
    updateFilters({
      [filterName]: "",
    });
    onSubmit({ [filterName]: "" });
  }

  function handleSearchToggle(event) {
    event.preventDefault();
    setIsOpen(!isOpen);
  }

  return (
    <FormWrapper
      buttonName="Search Publications"
      isOpen={isOpen}
      onSearchToggle={handleSearchToggle}
    >
      <form {...(action && { action })} ref={formEl}>
        <div id="searchForm" className="m-search-form__search">
          <SearchInput
            label="Search publications"
            placeholder="Search by author, keywords, species"
            {...(!action && { onSubmit: handleSearch })}
          />
          <FilterPanelToggle onClick={toggleFilterPanel} isOpen={filtersOpen} />
          {filters.order && (
            <Select
              options={filters.order}
              label="Order By:"
              name="order"
              onChange={handleOrderChange}
              {...(!action && {
                value: query.order,
              })}
            />
          )}
        </div>
        <CurrentFilters
          isOpen={filtersOpen}
          filters={currentFilters}
          onRemoveFilter={handleRemoveFilter}
        />
        <FilterPanel
          isOpen={filtersOpen}
          {...(onSubmit && { onSubmit: handleFilterSubmit })}
        >
          {filters.species && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.species,
              })}
              options={filters.species}
              label="Species:"
              name="species"
              emptyOption="Species"
              hideLabel
            />
          )}
          {filters.author && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.author,
              })}
              options={filters.author}
              label="Author:"
              name="author"
              emptyOption="Author"
              hideLabel
            />
          )}
          {filters.research_topic && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.research_topic,
              })}
              options={filters.research_topic}
              label="Topic:"
              name="research_topic"
              emptyOption="Topic"
              hideLabel
            />
          )}
          {filters.year && (
            <Select
              {...(!action && {
                onChange: handleSelectChange,
                value: values.research_topic,
              })}
              options={filters.year}
              label="Year:"
              name="year"
              emptyOption="Year"
              hideLabel
            />
          )}
        </FilterPanel>
      </form>
    </FormWrapper>
  );
};

PublicationSearchForm.propTypes = {
  action: PropTypes.string,
  filters: PropTypes.object,
  query: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default PublicationSearchForm;
