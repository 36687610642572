import PatientSearchPage from "./PatientSearchPage";
import PatientSearchForm from "./PatientSearchForm";
import PublicationSearchForm from "./PublicationSearchForm";
import PublicationSearchPage from "./PublicationSearchPage";
import NewsSearchForm from "./NewsSearchForm";
import NewsSearchPage from "./NewsSearchPage";
import LearningResourcesSearchForm from "./LearningResourcesSearchForm";
import LearningResourcesSearchPage from "./LearningResourcesSearchPage";

export default {
  PatientSearchPage,
  PatientSearchForm,
  PublicationSearchForm,
  PublicationSearchPage,
  NewsSearchForm,
  NewsSearchPage,
  LearningResourcesSearchForm,
  LearningResourcesSearchPage,
};
