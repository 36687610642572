import Sb from "../abstract/StatefulBehavior";
import { ResizeObserver as RoPolyfill } from "@juggle/resize-observer";

const ResizeObserver = (window && window.ResizeObserver) || RoPolyfill;

export default class ExpandContent extends Sb {
  constructor(el, props, refs) {
    super();

    if (!refs.toggle || !refs.contentWrapper) return;

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.state = {
      expanded: false,
      height: this.collapsedHeight,
    };

    this.update();
    this.bindToggle();

    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.el);
  }

  get collapsedHeight() {
    const { toggle, snippet } = this.refs;
    return this.elHeight(toggle) + this.elHeight(snippet);
  }

  get fullHeight() {
    const { snippet, readmoreContent } = this.refs;
    return this.elHeight(snippet) + this.elHeight(readmoreContent);
  }

  update = () => {
    const { toggle, contentWrapper } = this.refs;
    const { expanded, height } = this.state;

    toggle.setAttribute("aria-expanded", expanded);
    contentWrapper.setAttribute("aria-hidden", !expanded);
    this.setHeight();
  };

  setHeight = () => {
    const { expanded, height } = this.state;

    this.el.style.setProperty("--readmore-area-height", `${height}px`);
  };

  bindToggle() {
    this.refs.toggle.addEventListener("click", this.handleToggle);
  }

  handleToggle = (event) => {
    if (this.state.expanded) return false;
    // Don't click the wrapper also
    event.stopPropagation();
    this.refs.toggle.removeEventListener("click", this.handleToggle);
    this.resizeObserver.unobserve(this.el);
    this.setState({ expanded: true, height: this.fullHeight });
  };

  handleResize = (entries) => {
    entries.forEach((entry) => {
      if (
        entry.target === this.el &&
        this.state.height !== this.collapsedHeight
      ) {
        this.setState({ height: this.collapsedHeight });
      }
    });
  };

  elHeight(el) {
    return Math.round(el.getBoundingClientRect().height);
  }
}
