import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useHistory } from "react-router-dom";
import Arrow from "./Arrow";
import ContentBlock from "../ContentBlock";
import { formatQuery, getQuery } from "../../hooks/useQuery";
import { useBreakpoint } from "../../hooks";

const NextPage = () => (
  <>
    <Arrow direction="next" />
    <span className="a-hidden">Next page</span>
  </>
);

const PrevPage = () => (
  <>
    <Arrow direction="previous" />
    <span className="a-hidden">Previous page</span>
  </>
);

const Pagination = ({ totalPages, currentPage }) => {
  const location = useLocation();
  const history = useHistory();
  const isMobile = useBreakpoint(620);
  const pageInput = useRef(null);
  const query = getQuery(location.search);
  const pages = [];

  const boundary = isMobile ? 1 : 2;

  const handleGoToPage = function (event) {
    event.preventDefault();
    const qsValue = formatQuery({ ...query, page: pageInput.current.value });
    history.push(location.pathname + qsValue);
  };

  for (let i = 1; i <= totalPages; i++) {
    if (
      i < boundary + 1 ||
      i > totalPages - boundary ||
      (i >= currentPage - boundary && i <= currentPage + boundary)
    ) {
      pages.push(
        <li className="m-pagination__page" key={i}>
          {i === currentPage ? (
            <span
              className="m-pagination__link m-pagination__link--disabled"
              aria-label={`Current page ${i}`}
            >
              {i}
            </span>
          ) : (
            <Link
              to={formatQuery({ ...query, page: i })}
              className="m-pagination__link t-link"
              aria-label={`Page ${i}`}
            >
              {i}
            </Link>
          )}
        </li>
      );
    } else if (i === boundary + 1 || i === totalPages - boundary) {
      pages.push(
        <li className="m-pagination__page m-pagination__separator" key={i}>
          ...
        </li>
      );
    }
  }

  return (
    <ContentBlock>
      <nav className="m-pagination" aria-label="Page navigation">
        <form onSubmit={handleGoToPage} className="a-hidden">
          <label htmlFor="goto">Go to Page:</label>
          <input
            id="goto"
            type="number"
            min="1"
            max={totalPages}
            name="page"
            ref={pageInput}
          />
          <button type="submit">Go</button>
        </form>
        <ul className="m-pagination__list">
          <li className="m-pagination__prev">
            {currentPage === 1 ? (
              <span className="m-pagination__link m-pagination__link--disabled">
                <PrevPage />
              </span>
            ) : (
              <Link
                className="m-pagination__prev"
                to={formatQuery({ ...query, page: currentPage - 1 })}
              >
                <PrevPage />
              </Link>
            )}
          </li>
          {pages}
          <li className="m-pagination__next">
            {currentPage === totalPages ? (
              <span className="m-pagination__link m-pagination__link--disabled">
                <NextPage />
              </span>
            ) : (
              <Link
                className="m-pagination__next"
                to={formatQuery({ ...query, page: currentPage + 1 })}
              >
                <NextPage />
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </ContentBlock>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  location: PropTypes.object,
};

export default Pagination;
