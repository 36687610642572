import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IconArrow } from "../icons";
import { useBreakpoint } from "../hooks";

const FormWrapper = ({ children, buttonName, isOpen, onSearchToggle }) => {
  const isMobile = useBreakpoint(620);

  useEffect(() => {
    const action = isOpen && isMobile ? "add" : "remove";
    document.body.classList[action]("has-scroll-lock");
  }, [isOpen, isMobile]);

  return (
    <>
      <div className="m-search-form__toggle-wrapper">
        <button
          onClick={onSearchToggle}
          className="m-search-form__toggle m-button m-button--block"
          type="button"
        >
          {buttonName}
        </button>
      </div>
      <div
        className="a-bg-neutral10 m-search-form"
        hidden={!isOpen && isMobile}
      >
        <div className="m-search-form__back-wrapper">
          <button
            onClick={onSearchToggle}
            className="m-search-form__back"
            type="button"
          >
            <span className="a-hidden">Close</span>
            <IconArrow rotate={180} />
          </button>
        </div>
        {children}
      </div>
    </>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node,
  buttonName: PropTypes.string,
  isOpen: PropTypes.bool,
  onSearchToggle: PropTypes.func,
};

export default FormWrapper;
