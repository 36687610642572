import React from "react";
import PropTypes from "prop-types";

const CurrentFilters = ({ filters, isOpen, onRemoveFilter }) => {
  function handleRemoveFilter(event) {
    event.preventDefault();
    if (!onRemoveFilter) return;
    onRemoveFilter(event.target.dataset.key);
  }

  return (
    <div
      className="m-search-form__selected"
      aria-label="Current Filters"
      hidden={isOpen}
    >
      {filters && (
        <ul className="m-search-form__selected-list">
          {filters.map(({ key, name }) => (
            <li key={key} className="m-search-form__selected-item">
              <button
                onClick={handleRemoveFilter}
                className="m-search-form__selected-item"
                data-key={key}
              >
                <svg
                  className="m-search-form__remove-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 25 25"
                  width="12"
                  height="12"
                  role="img"
                >
                  <title>Close icon</title>
                  <path d="M13.8 12.2l9.9-10c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0l-9.9 10-9.9-10c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l9.9 10L2 22c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l9.7-9.7 9.7 9.7c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1l-9.6-9.8z" />
                </svg>
                <span className="m-search-form__remove-text a-hidden">
                  Remove {name}
                </span>
              </button>
              <span>{name}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

CurrentFilters.propTypes = {
  filters: PropTypes.array,
  isOpen: PropTypes.bool,
  onRemoveFilter: PropTypes.func,
};

export default CurrentFilters;
