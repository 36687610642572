/*
Creates and updates the values of CSS custom properties from DOM measurements
*/
import debounce from "lodash/debounce";

export default class SetCSSProperties {
  constructor() {
    this.setProperties();
    this.bindEvents();
  }

  setProperties() {
    this.setHeight("site-header");
    this.setHeight("site-notification");
    this.setHeight("site-nav-primary");
    this.setWidth("site-nav-primary");
  }

  setHeight(id) {
    const el = document.querySelector(`#${id}`);
    if (!el) return;
    const height = el.getBoundingClientRect().height;
    this.setCSSProperty(`--${id}-height`, `${height}px`);
  }

  setWidth(id) {
    const el = document.querySelector(`#${id}`);
    if (!el) return;
    const width = el.getBoundingClientRect().width;
    this.setCSSProperty(`--${id}-width`, `${width}px`);
  }

  bindEvents() {
    const debouncedClientSize = debounce(() => this.setProperties(), 200);
    window.addEventListener("resize", debouncedClientSize);
    window.addEventListener("transitionend", debouncedClientSize);
  }

  setCSSProperty(name, value) {
    document.documentElement.style.setProperty(name, value);
  }
}
