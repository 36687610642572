export default class SetTrackingUrl {
  constructor(el, props, refs) {
    this.el = el;
    this.props = props;
    this.bindEvents();
    this.handleUrlChange();
  }

  bindEvents() {
    // Listen for changes to the url
    // These events are manually pushed in LoadTabContent
    window.addEventListener("popstate", this.handleUrlChange);
  }

  handleUrlChange = (event) => {
    if (this.props.baseUrl.slice(-3) === "ms=") {
      const path = window.location.pathname.split("/");
      const slug = path[path.length - 1] ? path.pop() : "home";
      this.el.setAttribute("href", `${this.props.baseUrl}${slug}`);
    }
  };
}
