import "whatwg-fetch";

export default function pageApiRequest(apiPath, onSuccess, onError) {
  const controller = new AbortController();
  const { signal } = controller;

  const promise = window
    .fetch(apiPath, {
      headers: {
        Accept: "application/json",
      },
      credentials: "same-origin",
      signal,
    })
    .then(
      (response) => {
        onSuccess(response);
      },
      (error) => {
        throw new Error(error);
      }
    )
    .catch((error) => {
      onError(error);
    });
  return { promise, controller };
}
