import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { patientShape } from "../../shapes";

class PatientRecord extends PureComponent {
  render() {
    const {
      patient: {
        image_retina: imageRetina,
        image_default: imageDefault,
        name,
        sex,
        status,
        url,
        species_name: speciesName,
        species_threatened_status: threatenedStatus,
      },
    } = this.props;

    const WIDTH = 216;
    const HEIGHT = 168;

    return (
      <div className="m-record-results__item">
        {(imageRetina || imageDefault) && (
          <figure className="m-record-results__figure">
            <a
              href={url}
              className="m-image-link m-record-results__image-link"
              aria-hidden="true"
            >
              <div className="m-image">
                <img
                  className="m-image__img m-record-results__img"
                  src={imageDefault}
                  srcSet={`${imageRetina} ${
                    WIDTH * 2
                  }w, ${imageDefault} ${WIDTH}w`}
                  sizes={`(max-width: 620px) 148px, 100vw`}
                  width={WIDTH}
                  height={HEIGHT}
                  alt={""}
                  loading="lazy"
                />
              </div>
            </a>
            {threatenedStatus && threatenedStatus !== "not_threatened" && (
              <figcaption className="t-copy-label a-tag m-record-results__figcaption">
                {threatenedStatus}
              </figcaption>
            )}
          </figure>
        )}
        <div className="m-record-results__body">
          <h3 className="t-heading-quinary m-record-results__title">
            <a className="t-link" href={url}>
              {name}
            </a>
          </h3>
          <div className="t-copy-small m-record-results__text">
            {speciesName
              ? sex
                ? `${speciesName} — ${sex}`
                : speciesName
              : sex}
          </div>
          {status && (
            <div className="t-copy-label m-record-results__label">{status}</div>
          )}
        </div>
      </div>
    );
  }
}

PatientRecord.propTypes = {
  patient: PropTypes.shape(patientShape).isRequired,
};

export default PatientRecord;
