import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery, useApi } from "../../hooks";
import { LoadingIndicator, NoResults, Pagination } from "../../shared";
import PublicationSearchForm from "../PublicationSearchForm";
import PublicationResults from "./PublicationResults";
import { scrollToRef } from "../../helpers";

const PublicationSearchPage = ({ filters }) => {
  const [query, setQuery] = useQuery();
  const [state, fetch, fetchNextPage] = useApi("/api/v1/publications");
  const scrollRef = useRef(null);

  const { isLoading, isError, records, meta, pagination } = state;

  useEffect(() => {
    fetch(query);
  }, [query]); // eslint-disable-line

  useEffect(() => {
    if (isLoading) scrollToRef(scrollRef);
  }, [isLoading]);

  function handleSubmit(values) {
    setQuery({ ...query, ...values });
  }

  return (
    <div ref={scrollRef}>
      <PublicationSearchForm
        onSubmit={handleSubmit}
        filters={filters}
        query={query}
      />
      <div aria-live="assertive">
        {records && records.length > 0 && (
          <>
            <PublicationResults records={records} total={meta.total} />
            {pagination.totalPages > 1 && (
              <Pagination
                totalPages={pagination.totalPages}
                currentPage={pagination.currentPage}
              />
            )}
          </>
        )}
        {isLoading && <LoadingIndicator />}
        {!isLoading && !isError && meta && meta.total === 0 && <NoResults />}
        {!isLoading && isError && <NoResults isError />}
      </div>
    </div>
  );
};

PublicationSearchPage.propTypes = {
  filters: PropTypes.object,
};

export default PublicationSearchPage;
