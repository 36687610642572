/*
HtmlEmbed
*/
import { ResizeObserver as RoPolyfill } from "@juggle/resize-observer";
const ResizeObserver = (window && window.ResizeObserver) || RoPolyfill;

export default class HtmlEmbed {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;
    this.props = props;

    // If this element is inside of LoadTabContent,
    // we need to load the content into an iframe
    const tabEl = el.closest("[data-behavior=LoadTabContent]");
    if (tabEl) {
      this.createIframe();
    }
  }

  createIframe = () => {
    const { content } = this.refs;
    const html = content.innerHTML;

    content.innerHTML = ""; // TODO: Replace with loading indicator
    const iframe = document.createElement("iframe");
    iframe.classList.add("m-htmlembed__iframe");
    content.appendChild(iframe);
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(html);
    iframe.contentWindow.document.close();
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(iframe);
    this.iframe = iframe;
    // Set height after scripts inside iframe finish loading
    setTimeout(this.setHeight, 1000);
  };

  handleResize = (entries) => {
    entries.forEach(({ target }) => {
      if (target === this.iframe) {
        this.setHeight();
      }
    });
  };

  setHeight = (iframe) => {
    this.iframe.style.height =
      this.iframe.contentWindow.document.body.scrollHeight + "px";
  };
}
