import Flickity from "flickity";
import Sb from "../abstract/StatefulBehavior";
import flickityOptions from "../../helpers/flickityOptions";
import Percolator from "../../helpers/Percolator";
import ObjectFit from "./ObjectFit";
import { focusable as domFocusable } from "../../helpers/utilities";

export default class SlideBlocks extends Sb {
  constructor(el, props, refs) {
    super();

    this.props = props;
    this.refs = refs;

    this.state = {
      active: 0,
    };

    this.sliderEl = refs.slider || el;
    const options = {
      ...flickityOptions,
      lazyLoad: this.props.lazyLoad || 1,
      watchCSS: props.watchCSS,
      groupCells: this.props.groupCells || false,
    };

    // optionally instantiate ObjectFit behavior on slides
    // ensures behavior has run prior to Flickity instantiation so that
    // Flickity's inline styles on slides aren't overridden
    if (props.objectFit) {
      const percolator = new Percolator();
      const size = "cover";

      this.images = [...this.sliderEl.children].map((child, index) => {
        const image = child.querySelector("img");
        if (!image) return;
        return percolator.loadBehavior(image, ObjectFit, { size: size });
      });
    }

    this.slider = new Flickity(this.sliderEl, options);
    this.update();
    this.bindEvents();
  }

  get slideCount() {
    return this.slider.slides.length || 0;
  }

  get slides() {
    const { slide } = this.refs;
    return Array.isArray(slide) ? slide : Array(slide);
  }

  get activeImg() {
    if (!this.refs.image || this.refs.image.length === 0) {
      return null;
    }
    return this.refs.image[this.state.active];
  }

  get activeSlide() {
    if (!this.slides || this.slides.length === 0) {
      return null;
    }
    return this.slides[this.state.active];
  }

  update = () => {
    const { current } = this.refs;
    const { active } = this.state;

    this.slider.select(active);

    this.slides.forEach((slide, index) => {
      const focusable = domFocusable(slide);
      const isActive = index === active;
      focusable.forEach((el) => el.setAttribute("tabindex", isActive ? 0 : -1));
    });

    if (current) current.textContent = active + 1;

    const { previousButton, nextButton } = this.refs;
    if (previousButton && active === 0) {
      previousButton.setAttribute("disabled", true);
    } else if (previousButton) {
      previousButton.removeAttribute("disabled");
    }

    if (nextButton && active === this.slides.length - 1) {
      nextButton.setAttribute("disabled", true);
    } else if (nextButton) {
      nextButton.removeAttribute("disabled");
    }
  };

  bindEvents() {
    const { previousButton, nextButton } = this.refs;

    if (previousButton) {
      previousButton.addEventListener("click", this.handlePreviousClick);
    }
    if (nextButton) {
      nextButton.addEventListener("click", this.handleNextClick);
    }

    this.slider.on("change", (index) => {
      this.setState({ active: index });
    });

    this.slider.on("lazyLoad", (event) => {
      event.target.classList.add("is-loaded");
    });
  }

  handlePreviousClick = (event) => {
    const { active } = this.state;

    const previousSlide = active === 0 ? this.slideCount - 1 : active - 1;
    this.setState({ active: previousSlide });
  };

  handleNextClick = (event) => {
    const { active } = this.state;
    const nextSlide = active === this.slideCount - 1 ? 0 : active + 1;
    this.setState({ active: nextSlide });
  };
}
