import pageApiRequest from "../../api/Page";
import Sb from "../abstract/StatefulBehavior";
import Percolator from "../../helpers/Percolator";
import behaviors from "../../behaviors";
import LazyLoad from "./LazyLoad";
import components from "../../components";

export default class LoadTabContent extends Sb {
  constructor(el, props, refs) {
    super();

    if (!refs.tabnavigation || !refs.tabcontent) return null;

    this.el = el;
    this.props = props;
    this.refs = refs;
    this.activeRequest = null;

    this.initState();
    this.addListeners();
    this.update();
  }

  get linkElements() {
    return [...this.refs.tabnavigation.querySelectorAll("a")];
  }

  get linkUrls() {
    return this.linkElements.map((link) => link.getAttribute("href"));
  }

  get activeTab() {
    return this.tabs[this.state.tabIndex];
  }

  get activeLink() {
    return this.linkElements[this.state.tabIndex];
  }

  get tabs() {
    return this.refs.tabnavigation.children;
  }

  initState() {
    let tabIndex = 0;
    let path = this.linkUrls[tabIndex];
    let updateLocation = true;

    this.linkUrls.forEach((url, i) => {
      if (window.location.pathname === url) {
        tabIndex = i;
        path = url;
        updateLocation = false;
      }
    });

    this.state = {
      path,
      tabIndex,
      updateLocation,
    };
  }

  addListeners() {
    const { tabnavigationToggle } = this.refs;

    this.linkElements.forEach((link) =>
      link.addEventListener("click", this.handleLinkClick)
    );
    if (tabnavigationToggle) {
      tabnavigationToggle.addEventListener("click", this.handleToggle);
    }
    // Bind popstate to track back button
    window.addEventListener("popstate", this.handlePopState);
  }

  update = () => {
    this.setActiveTab();
    this.updateLocation();
    this.cancelRequest();
    this.loadContent();
  };

  handlePopState = (event) => {
    if (!event.state) return;

    const { url } = event.state;
    if (url && this.linkUrls.includes(url)) {
      this.setState({ path: url, updateLocation: false });
    }
  };

  handleLinkClick = (event) => {
    event.preventDefault();
    const href = event.target.getAttribute("href");

    if (href !== this.state.path) {
      const { index } = event.target.dataset;
      this.setState({
        path: href,
        tabIndex: parseInt(index),
        updateLocation: true,
      });
    }
  };

  handleToggle = (event) => {
    if (event) event.preventDefault();
    const { tabnavigationToggle, tabnavigation } = this.refs;
    if (!tabnavigationToggle) return;
    const expanded =
      tabnavigationToggle.getAttribute("aria-selected") === "true";
    tabnavigationToggle.setAttribute("aria-selected", !expanded);
    tabnavigation.setAttribute("aria-expanded", !expanded);
  };

  setActiveTab() {
    this.linkElements.forEach((tab) =>
      tab.setAttribute("aria-selected", false)
    );
    if (!this.activeLink) return;
    this.activeLink.setAttribute("aria-selected", true);
  }

  updateLocation() {
    if (this.state.updateLocation) {
      const url = this.state.path;
      window.history.pushState({ url }, null, url);
      window.dispatchEvent(new Event("popstate"));
    }
  }

  loadContent() {
    const { path } = this.state;

    const request = pageApiRequest(
      this.hrefToApi(path),
      this.handleLoadSuccess,
      this.handleLoadError
    );
    this.renderLoading(true);
    this.activeRequest = request;
  }

  cancelRequest(request) {
    if (this.activeRequest) {
      const { promise, controller } = this.activeRequest;
      controller.abort();
      this.activeRequest = null;
    }
  }

  handleLoadSuccess = (response) => {
    if (!response.ok) {
      throw Error(response);
    } else {
      this.renderContent(response);
    }
  };

  handleLoadError = () => {
    this.renderLoading(false);
    // TODO: Do not render error if request was cancelled
    this.renderError();
  };

  renderContent(response) {
    response.json().then((data) => {
      this.refs.tabcontent.innerHTML = data.content;
      this.loadBehaviorsAndComponents();
      this.renderLoading(false);
      if (window.location.hash) {
        setTimeout(this.scrollToHash, 300);
      }
    });
  }

  scrollToHash() {
    const target = window.location.hash;
    const el = document.querySelector(target);
    if (el) {
      window.scrollTo(0, el.offsetTop);
    }
  }

  renderLoading(show) {
    const { tabloading, tabcontent } = this.refs;
    if (!tabloading) return;

    if (show) {
      tabcontent.classList.add("m-tabs__content--loading");
      tabloading.removeAttribute("hidden");
    } else {
      tabcontent.classList.remove("m-tabs__content--loading");
      tabloading.setAttribute("hidden", true);
    }
  }

  renderError() {}

  loadBehaviorsAndComponents() {
    const percolator = new Percolator();
    const { tabcontent } = this.refs;

    percolator.loadBehavior(tabcontent, LazyLoad);
    percolator.loadBehaviors(behaviors.content, tabcontent);
    percolator.loadComponents(components.content, tabcontent);
  }

  hrefToApi(href) {
    return `/content${href}`;
  }
}
