import "core-js/stable";
import "regenerator-runtime/runtime";
import "focus-visible";
import "intersection-observer";
import "ie11-custom-properties";
import "details-element-polyfill";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";
import polyfill from "./javascript/helpers/polyfill";
import "./style/stylesheets/styles.scss";
import Percolator from "./javascript/helpers/Percolator";

import behaviors from "./javascript/behaviors";
import components from "./javascript/components";

// Run local polyfills
polyfill.customEvent();

const percolator = new Percolator();

percolator.loadBehaviors(behaviors.site);
percolator.loadBehaviors(behaviors.content);
percolator.loadComponents(components.content);
percolator.loadComponents(components.components);
