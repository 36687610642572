import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useBreakpoint } from "../hooks";

const FilterPanel = ({ children, isOpen, onSubmit }) => {
  const isMobile = useBreakpoint(620);

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(isMobile);
  }

  return (
    <div
      className={classNames("m-filters-panel", {
        "a-slide-in": isOpen || isMobile,
        "a-slide-out": !isOpen && !isMobile,
      })}
      {...(!isMobile && { role: "tabpanel" })}
      hidden={!isOpen && !isMobile}
      id="filtersPanel"
    >
      <fieldset className="m-filters-panel__wrapper">
        <div className="m-filters-panel__filters">
          <legend
            className={classNames("t-copy-label", {
              "a-hidden": !isMobile,
            })}
          >
            Filter By:
          </legend>
          {children}
        </div>
        <button
          className="m-button m-button--block m-filters-panel__apply"
          {...(onSubmit && { onClick: handleSubmit })}
          type="submit"
        >
          Apply
        </button>
      </fieldset>
    </div>
  );
};

FilterPanel.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default FilterPanel;
